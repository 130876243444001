// extracted by mini-css-extract-plugin
export var socialContainer = "B_t8";
export var instagramLink = "B_t9";
export var socialWrapperLeft = "B_nq d_dy d_W d_bw d_bF d_bl d_bC";
export var socialContentContainer = "B_vb";
export var socialContentContainerFull = "B_vc";
export var instagramContainer = "B_vd";
export var twitterContainer = "B_vf";
export var facebookContainer = "B_vg";
export var socialErrorContainer = "B_vh";
export var facebookContainerWide = "B_vj";
export var twitterContainerWide = "B_vk";
export var socialParagraphCenter = "B_vl";
export var instaWrapper = "B_vm";
export var SubtitleSmall = "B_qM z_qM z_s7 z_tk";
export var SubtitleNormal = "B_qN z_qN z_s7 z_tl";
export var SubtitleLarge = "B_qP z_qP z_s7 z_tm";