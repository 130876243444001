// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rL d_fl d_bC d_dr";
export var alignLeft = "s_q4 d_fl d_bC d_dr";
export var alignDiscCenter = "s_rM d_fm d_bz d_ds";
export var alignCenter = "s_bL d_fm d_bz d_ds";
export var alignDiscRight = "s_rN d_fn d_bD d_dt";
export var alignRight = "s_q5 d_fn d_bD d_dt";
export var footerContainer = "s_rP d_dS d_bS";
export var footerContainerFull = "s_rQ d_dQ d_bS";
export var footerHeader = "s_kc d_kc";
export var footerTextWrapper = "s_rR d_s";
export var footerDisclaimerWrapper = "s_kk d_kk d_cg";
export var badgeWrapper = "s_rS d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "s_kl d_kl d_bw";
export var footerDisclaimerLeft = "s_km d_km d_bw";
export var verticalTop = "s_rT d_bw d_bF d_bK d_bH";
export var firstWide = "s_rV";
export var disclaimer = "s_rW";
export var socialDisclaimer = "s_rX";
export var left = "s_rY";
export var wide = "s_rZ d_cv";
export var right = "s_r0 d_bG";
export var line = "s_fg d_fh d_cr";
export var badgeDisclaimer = "s_r1 d_by d_bL d_bF";
export var badgeContainer = "s_r2 d_bw d_bD d_bL";
export var badge = "s_r3";
export var padding = "s_r4 d_c4";
export var end = "s_r5 d_bD";
export var linkWrapper = "s_r6 d_dx";
export var link = "s_mF d_dx";
export var colWrapper = "s_r7 d_ct";
export var media = "s_r8 d_bt d_dv";
export var itemRight = "s_r9";
export var itemLeft = "s_sb";
export var itemCenter = "s_sc";
export var exceptionWeight = "s_sd z_tz";