// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "q_qY d_bz";
export var datasheetRowWrapper = "q_lx d_lx d_bG";
export var datasheetLeftWrapper = "q_qZ d_bw d_bL";
export var datasheetWrapperFull = "q_q0 d_cz";
export var datasheetWrapperFullLeft = "q_nK d_cz d_bw d_bL d_bz d_bQ";
export var contentWrapper = "q_mC d_ly";
export var contentWrapperCenter = "q_q1 d_lD";
export var datasheetLeftWrapperCenter = "q_q2 d_lB d_bw d_bz d_bL d_bF";
export var datasheetImageCenterWrapper = "q_lv d_lv d_s d_cr";
export var datasheetRightWrapperCenter = "q_q3 d_lz";
export var datasheetFooter = "q_lC d_lC";
export var alignLeft = "q_q4 d_dr";
export var alignCenter = "q_bL d_ds";
export var alignRight = "q_q5 d_dt";
export var datasheetInnerWrapperNewLeft = "q_q6 d_fb d_L d_W d_bw d_bL d_df";
export var datasheetInnerWrapperLeft = "q_q7 d_d9 d_L d_W d_bw d_bL d_b2 d_df d_c6";
export var datasheetInnerWrapperRight = "q_q8 d_fb d_L d_W d_bw d_bL d_df";
export var datasheetRowWrapperReverse = "q_q9 d_bG";
export var datasheetSubtitle = "q_rb d_ct";
export var tableRow = "q_rc";
export var cell = "q_rd";
export var tableRowWrapper = "q_rf d_s d_dr";
export var tableHeadingCell = "q_rg";
export var tableHeading = "q_rh";
export var tableRowStriped = "q_rj";
export var tableRowHeading = "q_rk";
export var dataTable = "q_rl d_s";
export var imageWrapper = "q_rm d_fc d_W";
export var imageWrapperFull = "q_rn d_D d_s d_bb d_W";
export var imageWrapperIcon = "q_rp";
export var titleMargin = "q_rq d_cp";
export var datasheetInnerInnerWrapper = "q_rr d_s";
export var hasLabels = "q_rs";
export var label = "q_rt";
export var SmallSmall = "q_rv z_rv z_s7 z_tv";
export var SmallNormal = "q_rw z_rw z_s7 z_tw";
export var SmallLarge = "q_rx z_rx z_s7 z_ts";