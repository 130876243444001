// extracted by mini-css-extract-plugin
export var articleWrapper = "n_p8";
export var articleText = "n_p9 d_dr d_cp";
export var header = "n_qb d_W d_s";
export var headerImageWrapper = "n_qc d_9 d_X d_5 d_4 d_1 d_6 d_bg d_bN";
export var headerGradient = "n_qd d_9 d_X d_5 d_4 d_1 d_6";
export var headerGradientOverlay = "n_qf d_9 d_X d_5 d_4 d_1 d_6";
export var headerContentWrapper = "n_qg d_bw d_bL d_bz d_bF d_bh d_W";
export var headerContentWrapperAlt = "n_qh d_bw d_bL d_bz d_bF d_bh d_W";
export var contentWrapper = "n_mC d_s";
export var dateTag = "n_qj d_cb d_cn d_dm d_s";
export var icon1 = "n_qk d_cL";
export var icon2 = "n_ql d_cL d_cF";
export var tagBtn = "n_qm d_bY d_cz d_dl d_dx";
export var headerText = "n_qn d_s d_cb d_cp d_bS";
export var center = "n_qp d_ds";
export var videoIframeStyle = "n_qq d_d2 d_s d_D d_bv d_bY d_N";
export var articleImageWrapper = "n_qr d_cp d_W";
export var articleImageWrapperIcon = "n_qs d_cp d_W";
export var articleRow = "n_qt d_bz d_bb";
export var quoteWrapper = "n_gN d_by d_bL d_cp";
export var quoteBar = "n_qv d_D";
export var quoteText = "n_qw";
export var authorBox = "n_qx d_s";
export var authorRow = "n_qy d_bz d_bS d_cv";
export var separator = "n_qz d_s";
export var line = "n_fg d_s d_fg d_cV d_cv";
export var authorImage = "n_lr d_b4 d_W d_s d_cq";
export var authorText = "n_qB d_cd d_cq";
export var masonryImageWrapper = "n_qC";
export var bottomSeparator = "n_qD d_s d_cv";
export var linksWrapper = "n_qF d_cd d_dr";
export var comments = "n_qG d_bz d_cv";
export var sharing = "n_qH d_bw d_bz d_bF";
export var shareText = "n_qJ d_s d_ds";
export var icon = "n_qK";
export var text = "n_qL";
export var SubtitleSmall = "n_qM";
export var SubtitleNormal = "n_qN";
export var SubtitleLarge = "n_qP";