// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sn d_gs d_cp";
export var heroHeaderCenter = "v_gt d_gt d_cp d_ds";
export var heroHeaderRight = "v_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "v_sp d_gp d_cs";
export var heroParagraphCenter = "v_gq d_gq d_cs d_ds";
export var heroParagraphRight = "v_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "v_sq d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "v_sr d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "v_ss d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "v_st d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "v_sv d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "v_sw z_sw";
export var heroExceptionNormal = "v_sx z_sx";
export var heroExceptionLarge = "v_sy z_sy";
export var Title1Small = "v_sz z_sz z_s7 z_s8";
export var Title1Normal = "v_sB z_sB z_s7 z_s9";
export var Title1Large = "v_sC z_sC z_s7 z_tb";
export var BodySmall = "v_sD z_sD z_s7 z_tr";
export var BodyNormal = "v_sF z_sF z_s7 z_ts";
export var BodyLarge = "v_sG z_sG z_s7 z_tt";