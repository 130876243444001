// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "C_vn d_bz";
export var storyRowWrapper = "C_hv d_hv d_bG";
export var storyLeftWrapper = "C_vp d_bw d_bL";
export var storyWrapperFull = "C_vq d_cz";
export var storyWrapperFullLeft = "C_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "C_mC d_hw";
export var storyLeftWrapperCenter = "C_vr d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "C_vs d_hC";
export var storyHeader = "C_vt d_hB d_s d_cp";
export var storyHeaderCenter = "C_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "C_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "C_vv d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "C_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "C_rm d_fc d_W";
export var imageWrapperFull = "C_rn d_s d_D d_bb d_W";