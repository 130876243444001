// extracted by mini-css-extract-plugin
export var tileContent = "y_sT d_s d_D d_W";
export var teamTextLeft = "y_sV d_dr";
export var teamTextCenter = "y_sW d_ds";
export var teamTextRight = "y_sX d_dt";
export var alignLeft = "y_q4 d_s d_bw d_fl d_bC d_dr";
export var alignCenter = "y_bL d_s d_bw d_fm d_bz d_ds";
export var alignRight = "y_q5 d_s d_bw d_fn d_bD d_dt";
export var teamContainer = "y_sY d_dS";
export var teamContainerFull = "y_sZ d_dQ";
export var teamRowWrapper = "y_s0 d_b8";
export var teamTileWrapper = "y_j1 d_j1 d_W d_cr";
export var teamTileSquareWrapper = "y_s1 d_j2 d_W d_cr";
export var teamTileRoundWrapper = "y_j2 d_j2 d_W d_cr";
export var teamTileNoGuttersWrapper = "y_j3 d_j3 d_W";
export var teamHoverNoGutters = "y_j4 d_j4 d_X d_s d_D d_bw d_bz d_bL d_bj";
export var teamImageText = "y_j9 d_j9 d_bh d_s d_bv";
export var teamInfoWrapperSquare = "y_s2 d_j5";
export var teamInfoWrapperRound = "y_j5 d_j5";
export var teamInfoWrapper = "y_s3 d_j6 d_X";
export var teamInfoWrapperNoGutters = "y_j7 d_j7 d_s d_D d_bz d_bJ d_bF d_c4";
export var teamImgWrapper = "y_jX d_jX";
export var teamImgWrapperAlt = "y_jY d_jY";
export var teamImgWrapperNoGutters = "y_j8 d_j8";
export var teamHeader = "y_s4 d_cs";
export var teamHeaderAlt = "y_s5 d_cs";
export var teamHeaderNoGutters = "y_s6 d_cs d_cz";